import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FEEDBACK_MODAL_DELAY } from '../../../util/config'
import { isFeedbackMuted, setFeedbackMute } from '../../../util/surveyMuteUtils'
import { setShowFeedbackModal } from '../../../pages/Survey/surveySlice'

export function handleSurveyModal(intentionalLogout, dispatch) {
  if (intentionalLogout && !isFeedbackMuted()) {
    const timer = setTimeout(() => {
      dispatch(setShowFeedbackModal(true))
    }, FEEDBACK_MODAL_DELAY)

    return () => clearTimeout(timer)
  }
  return undefined
}

export function useLoginLogic(loggedOut) {
  const dispatch = useDispatch()
  const showFeedbackModal = useSelector((state) =>
    state.survey.showFeedbackModal)
  const [logoutWarning, setLogoutWarning] = useState(sessionStorage.getItem('logoutWarning'))

  const handleMute = useCallback(() => {
    setFeedbackMute()
    dispatch(setShowFeedbackModal(false))
  }, [dispatch])

  useEffect(() => {
    if (loggedOut === 'true') {
      sessionStorage.removeItem('wasLoggedIn')
      sessionStorage.removeItem('logoutWarning')
      setLogoutWarning(null)

      const intentionalLogout = sessionStorage.getItem('intentionalLogout') === 'true'
      return handleSurveyModal(intentionalLogout, dispatch)
    }
    sessionStorage.removeItem('wasLoggedIn')

    return () => {
      sessionStorage.removeItem('intentionalLogout')
    }
  }, [loggedOut, dispatch])

  return {
    logoutWarning, showFeedbackModal, handleMute
  }
}
