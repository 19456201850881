import * as React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl, Select, MenuItem, FormHelperText
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'
import styles from './LanguageSelector.module.scss'
import { Button } from '../section-components/Button'

// Helper function to map language codes to country codes
export const getCountryCode = (languageCode) => {
  const languageToCountryMap = {
    'en-US': 'US',
    'es-ES': 'ES',
    'ja-JP': 'JP',
    'ko-KR': 'KR',
    'nl-NL': 'NL',
    'de-DE': 'DE',
    'pt-PT': 'PT',
    'fr-FR': 'FR'
  }
  return languageToCountryMap[languageCode] || ''
}

export default function LanguageSelector({
  label,
  value,
  onChange,
  error,
  required,
  supportText,
  onCancel,
  onSave,
  saveButtonText,
  cancelButtonText,
  buttonStyles,
  languageOptions,
  onError,
  isMobile
}) {
  const { t } = useTranslation()

  const handleSave = async () => {
    try {
      await onSave()
    } catch (err) {
      onError(t('error-saveFailed'))
    }
  }

  const ctaButtonStyle = isMobile ? 'cta-red-oval-edit-profile-mobile' : 'cta-red-oval-edit-profile'

  return (
    <div>
      <div className={styles.languageSelectorLabel}>{t(label)}</div>
      <FormControl
        sx={{
          width: '32.5%',
          height: 46,
          '& .MuiOutlinedInput-input': {
            height: 'auto',
            padding: '12px 14px',
          },
          '& .MuiSelect-select': {
            height: 'auto',
            padding: '12px 14px',
          },
        }}
        error={error}
        required={required}
      >
        <Select
          id="language-selector"
          value={value}
          onChange={onChange}
          displayEmpty
          IconComponent={ExpandMoreIcon}
          renderValue={(selected) => (
            <>
              {selected && (
                <ReactCountryFlag
                  countryCode={getCountryCode(selected)}
                  svg
                  style={{ marginRight: 8, marginBottom: 2.5 }}
                />
              )}
              {t(languageOptions[selected])}
            </>
          )}
        >
          {Object.entries(languageOptions).map(([languageCode, languageKey]) => (
            <MenuItem key={languageCode} value={languageCode}>
              <ReactCountryFlag
                countryCode={getCountryCode(languageCode)}
                svg
                style={{ marginRight: 8 }}
              />
              {t(languageKey)}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText error>{supportText}</FormHelperText>}
      </FormControl>
      <div className={isMobile ? styles.buttonGroupMobile : styles.buttonGroup}>
        <Button
          text={t(saveButtonText)}
          onClick={handleSave}
          buttonStyle={ctaButtonStyle}
        />
        <Button
          text={t(cancelButtonText)}
          onClick={onCancel}
          buttonStyle={buttonStyles.cancelButton}
        />
      </div>
    </div>
  )
}

LanguageSelector.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  supportText: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  buttonStyles: PropTypes.objectOf(PropTypes.string),
  languageOptions: PropTypes.objectOf(PropTypes.string),
  onError: PropTypes.func,
  isMobile: PropTypes.bool
}

LanguageSelector.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  error: false,
  required: false,
  supportText: '',
  onSave: () => {},
  onCancel: () => {},
  onError: '',
  saveButtonText: '',
  cancelButtonText: '',
  buttonStyles: {
    saveButton: 'cta-red-oval-small',
    cancelButton: 'cta-grey-oval-small'
  },
  languageOptions: {
    'en-US': 'English',
    'es-ES': 'Español',
    'ja-JP': '日本語',
    'ko-KR': '한국어',
    'nl-NL': 'Nederlands',
    'de-DE': 'Deutsch',
    'pt-PT': 'Português',
    'fr-FR': 'Français'
  },
  isMobile: false
}
