import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import errorReducer from '../features/error/errorSlice'
import healthCheckReducer from '../features/health/healthCheckSlice'
import pageReducer from '../features/side-menu/SideMenuSlice'
import userAppsReducer from '../features/user-apps/UserAppsSlice'
import loginHistoryReducer from '../features/user/LoginHistorySlice'
import profilePictureReducer from '../features/user/profilePictureSlice'
import userReducer from '../features/user/userSlice'
import supportReducer from '../pages/Support/supportSlice'
import surveyReducer from '../pages/Survey/surveySlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    page: pageReducer,
    user: userReducer,
    userApps: userAppsReducer,
    error: errorReducer,
    healthCheck: healthCheckReducer,
    profilePicture: profilePictureReducer,
    support: supportReducer,
    survey: surveyReducer,
    loginHistory: loginHistoryReducer
  },
})
