/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import IconButton from './IconButton'
import styles from './EmotionSelect.module.scss'
import { ReactComponent as HappyIcon } from '../../assets/Happy.svg'
import { ReactComponent as NeutralIcon } from '../../assets/Neutral.svg'
import { ReactComponent as SadIcon } from '../../assets/Sad.svg'

function EmotionSelect({
  questionText, buttonLabels, buttonColors, selectedButton, onButtonClick
}) {
  const icons = [HappyIcon, NeutralIcon, SadIcon]

  return (
    <div className={styles.emotionSelect}>
      <h3 className={styles.question}>{questionText}</h3>
      <div className={styles.buttonsContainer}>
        {icons.map((Icon, index) => (
          <IconButton
            key={`${buttonLabels[index]}-${index}-${selectedButton}`}
            icon={Icon}
            selectionColor={buttonColors[index]}
            label={buttonLabels[index]}
            onClick={() => onButtonClick(index)}
            style={{
              borderColor: selectedButton === index ? buttonColors[index] : 'gray',
              color: selectedButton === index ? buttonColors[index] : 'gray',
            }}
          />
        ))}
      </div>
    </div>
  )
}

EmotionSelect.propTypes = {
  questionText: PropTypes.string.isRequired,
  buttonLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedButton: PropTypes.number,
  onButtonClick: PropTypes.func.isRequired,
}

EmotionSelect.defaultProps = {
  selectedButton: null,
}

export default EmotionSelect
