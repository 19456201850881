import { FEEDBACK_MODAL_MUTE_DURATION } from './config'

export const setFeedbackMute = () => {
  const muteDuration = parseInt(FEEDBACK_MODAL_MUTE_DURATION, 10) || 50
  const muteUntil = Date.now() + muteDuration * 60 * 1000
  localStorage.setItem('feedbackMuteUntil', muteUntil.toString())
}

export const isFeedbackMuted = () => {
  const muteUntil = localStorage.getItem('feedbackMuteUntil')
  if (!muteUntil) return false

  return parseInt(muteUntil, 10) > Date.now()
}
