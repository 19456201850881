import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Paper } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import { useLocation } from 'react-router-dom'
import styles from './Login.module.scss'
import isMobileView from '../../../util/MobileView'
import { Button } from '../../section-components/Button'
import { GATEWAY_API_LOGIN, SURVEY_TEMPLATE } from '../../../util/config'
import Alert from '../../error/Alert'
import FeedbackModal from '../../feedback-modal/FeedbackModal'
import { useLoginLogic } from './useLoginLogic'
import { setShowFeedbackModal } from '../../../pages/Survey/surveySlice'

export default function Login({ userObjData }) {
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState(isMobileView(541))
  const dispatch = useDispatch()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView(541))
    }

    const debounceResize = () => {
      clearTimeout(handleResize.debounce)
      handleResize.debounce = setTimeout(handleResize, 100)
    }

    window.addEventListener('resize', debounceResize)
    return () => {
      window.removeEventListener('resize', debounceResize)
    }
  }, [])
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const loggedOut = queryParams.get('logout')
  const {
    logoutWarning, showFeedbackModal, handleMute
  } = useLoginLogic(loggedOut)
  const buttonColors = ['#22BB02', '#F98E2B', '#BB0014']

  const [setSurveyResponses] = useState({})

  const handleSurveyChange = (newResponses) => {
    setSurveyResponses(newResponses)
  }

  const renderLoginContent = () => (
    <>
      {logoutWarning && (
      <div style={{ paddingBottom: '24px' }}>
        <Alert
          headerText={t('unauthorized-access-header')}
          message={t('unauthorized-access-message')}
          alertStyle="warning-alert"
          showClose={false}
        />
      </div>
      )}
      {loggedOut === 'true' && (
        <div style={{ paddingBottom: '24px' }}>
          <Alert
            headerText=""
            message={t('signed-out-message')}
            alertStyle="success-one-line"
            showClose={false}
          />
        </div>
      )}
      <div className={isMobile ? styles['mobile-login-title'] : styles['login-title']}>
        {loggedOut === 'true' ? t('welcome-signed-out') : t('welcome-message')}
      </div>
      <div className={isMobile ? styles['mobile-login-sub-title'] : styles['login-sub-title']}>
        {t('sign-in-blurb')}
      </div>
      <div className={isMobile ? styles['login-button-mobile'] : styles['login-button']}>
        <Button
          id="login_button_href"
          text={t('LogIn')}
          buttonStyle="cta-red-oval"
          onClick={() => {
            window.location.href = GATEWAY_API_LOGIN
            sessionStorage.setItem('wasLoggedIn', 'true')
            return false
          }}
        />
      </div>
      <div className={isMobile ? styles['mobile-login-footer-blurb'] : styles['login-footer-blurb']}>
        {t('sign-in-footer-blurb')}
      </div>
    </>
  )

  return (
    <div>
      <AppBar className={isMobile ? 'mobile-login-app-bar' : styles['login-app-bar']} sx={{ backgroundColor: '#EB1700', height: isMobile ? '100px' : '140px' }}>
        <Typography className={isMobile ? styles['mobile-login-app-bar-header'] : styles['login-app-bar-header']} sx={{ fontFamily: 'JohnsonDisplay', fontSize: '30px', fontWeight: 'bolder' }}>
          {t('sign-in-header')}
        </Typography>
      </AppBar>
      <div className={isMobile ? styles['full-viewport-height'] : undefined}>
        <Paper elevation={0} className={isMobile ? styles['mobile-login-content'] : styles['login-content']}>
          {renderLoginContent()}
        </Paper>
      </div>
      <FeedbackModal
        open={showFeedbackModal}
        onClose={() => dispatch(setShowFeedbackModal(false))}
        onMute={handleMute}
        surveySchema={SURVEY_TEMPLATE}
        userObjData={userObjData}
        buttonColors={buttonColors}
        onSurveyChange={handleSurveyChange}
      />
    </div>
  )
}

Login.propTypes = {
  userObjData: PropTypes.shape({
    preferredLanguage: PropTypes.string,
    firstName: PropTypes.string,
  }),
}

Login.defaultProps = {
  userObjData: {
    preferredLanguage: 'en-US',
    firstName: '',
  },
}
