import { useEffect } from 'react'

export const useUserData = (
  selectUserObject,
  selectUserCallStatus,
  selectProfilePictureImage,
  selectProfilePictureStatus,
  fetchUser,
  fetchProfilePicture,
  useSelector,
  useDispatch
) => {
  const dispatch = useDispatch()
  const userObjData = useSelector(selectUserObject)
  const userObjStatus = useSelector(selectUserCallStatus)
  const userProfilePictureData = useSelector(selectProfilePictureImage)
  const userProfilePictureStatus = useSelector(selectProfilePictureStatus)

  useEffect(() => {
    if (userObjStatus === 'idle') {
      dispatch(fetchUser())
    }
  }, [userObjStatus, dispatch, fetchUser])

  useEffect(() => {
    if (userProfilePictureStatus === 'idle') {
      dispatch(fetchProfilePicture())
    }
  }, [userProfilePictureStatus, dispatch, fetchProfilePicture])

  const isUserDataLoading = (userObjStatus === 'loading' || userObjStatus === 'idle'
      || userProfilePictureStatus === 'loading' || userProfilePictureStatus === 'idle')

  return {
    userObjData,
    userProfilePictureData,
    isUserDataLoading,
    userObjStatus,
    userProfilePictureStatus
  }
}
