import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import { SectionHeader } from '../../section-components/SectionHeader'
import JNJFooter from '../../jnj-footer/JNJFooter'
import styles from './Logout.module.scss'
import isMobileView from '../../../util/MobileView'
import { useLogoutLogic } from './useLogoutLogic'

export default function Logout() {
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState(isMobileView(646))

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView(646))
    }

    const debounceResize = () => {
      clearTimeout(handleResize.debounce)
      handleResize.debounce = setTimeout(handleResize, 100)
    }

    window.addEventListener('resize', debounceResize)
    return () => {
      window.removeEventListener('resize', debounceResize)
    }
  }, [])
  useLogoutLogic()

  return (
    <div className={styles['signout-container']}>
      <div className={styles['loading-icon']}>
        <CircularProgress />
      </div>
      <SectionHeader size="small" text={t('LoggingOut')} />
      {!isMobile && (
        <div className={styles['jnj-footer']}>
          <JNJFooter text={t('FooterText')} footerSize="medium" />
        </div>
      )}
    </div>
  )
}
