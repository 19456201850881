import React from 'react'
import PropTypes from 'prop-types'
import EmotionSelect from '../EmotionSelect'
import SurveyFreeFormQuestion from '../SurveyFreeFormQuestion'

export function QuestionRenderer({
  question, responses, buttonColors, onAnswerChange, getTranslation,
}) {
  const questionText = getTranslation(question.label)

  switch (question.type) {
    case 'emotion_select': {
      const buttonLabels = question.answers.map((a) => getTranslation(a))
      return (
        <EmotionSelect
          questionText={questionText}
          buttonLabels={buttonLabels}
          buttonColors={buttonColors}
          selectedButton={responses[question.id]}
          onButtonClick={(response) => onAnswerChange(question.id, response)}
        />
      )
    }
    case 'free_response':
      return (
        <SurveyFreeFormQuestion
          promptText={questionText}
          onTextChange={(response) => onAnswerChange(question.id, response)}
          textValue={responses[question.id] || ''}
        />
      )
    default:
      return null
  }
}

QuestionRenderer.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.objectOf(PropTypes.string).isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        'en-US': PropTypes.string.isRequired,
        'es-ES': PropTypes.string.isRequired,
        'ja-JP': PropTypes.string.isRequired,
        'ko-KR': PropTypes.string.isRequired,
        'nl-NL': PropTypes.string.isRequired,
        'de-DE': PropTypes.string.isRequired,
        'pt-PT': PropTypes.string.isRequired,
        'fr-FR': PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  responses: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  buttonColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired,
}
