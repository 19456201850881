import { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { GATEWAY_API_LOGOUT, API_AUTH_COOKIE_NAME } from '../../../util/config'
import { gatewayRequestAxios } from '../../../api/interceptor'

export function useLogoutLogic() {
  const navigate = useNavigate()

  const clearStorageExceptFeedbackMute = useCallback(() => {
    const feedbackMuteUntil = localStorage.getItem('feedbackMuteUntil')
    localStorage.clear()
    if (feedbackMuteUntil) {
      localStorage.setItem('feedbackMuteUntil', feedbackMuteUntil)
    }
    sessionStorage.removeItem('logoutWarning')
  }, [])

  const clearAuthCookie = useCallback(() => {
    document.cookie = `${API_AUTH_COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }, [])

  const setIntentionalLogout = useCallback(() => {
    sessionStorage.setItem('intentionalLogout', 'true')
  }, [])

  const navigateToLogin = useCallback(() => {
    navigate('/login?logout=true')
  }, [navigate])

  const performLogout = useCallback(async () => {
    await gatewayRequestAxios.get(GATEWAY_API_LOGOUT, {})
    clearStorageExceptFeedbackMute()
    clearAuthCookie()
    setIntentionalLogout()
    navigateToLogin()
  }, [clearStorageExceptFeedbackMute, clearAuthCookie, setIntentionalLogout, navigateToLogin])

  useEffect(() => {
    performLogout()
  }, [performLogout])

  return {
    performLogout,
    clearStorageExceptFeedbackMute,
    clearAuthCookie,
    setIntentionalLogout,
    navigateToLogin
  }
}
