import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { QuestionRenderer } from './QuestionRenderer'
import styles from './DynamicSurvey.module.scss'
import { setSurveyResponses } from '../../../pages/Survey/surveySlice'

function DynamicSurvey({
  surveySchema, userObjData, buttonColors, onChange,
}) {
  const preferredLanguage = userObjData.preferredLanguage || 'en-US'
  const dispatch = useDispatch()
  const responses = useSelector((state) => state.survey.surveyResponses)

  const getTranslation = (textObject) => textObject[preferredLanguage] || textObject['en-US']

  const handleAnswerChange = (questionId, answer) => {
    const updatedResponses = { ...responses, [questionId]: answer }
    dispatch(setSurveyResponses(updatedResponses))
    onChange(updatedResponses)
  }

  return (
    <div className={styles.surveyContainer}>
      {surveySchema.questions.map((question) => (
        <QuestionRenderer
          key={question.id}
          question={question}
          preferredLanguage={preferredLanguage}
          responses={responses}
          buttonColors={buttonColors}
          onAnswerChange={handleAnswerChange}
          getTranslation={getTranslation}
        />
      ))}
    </div>
  )
}

DynamicSurvey.propTypes = {
  surveySchema: PropTypes.shape({
    id: PropTypes.string.isRequired,
    schema: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.objectOf(PropTypes.string).isRequired,
        answers: PropTypes.arrayOf(
          PropTypes.shape({
            'en-US': PropTypes.string.isRequired,
            'es-ES': PropTypes.string.isRequired,
            'ja-JP': PropTypes.string.isRequired,
            'ko-KR': PropTypes.string.isRequired,
            'nl-NL': PropTypes.string.isRequired,
            'de-DE': PropTypes.string.isRequired,
            'pt-PT': PropTypes.string.isRequired,
            'fr-FR': PropTypes.string.isRequired,
          })
        ),
        required: PropTypes.bool,
        dependentOn: PropTypes.shape({
          id: PropTypes.string.isRequired,
          answer: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
  userObjData: PropTypes.shape({
    preferredLanguage: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  buttonColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default DynamicSurvey
