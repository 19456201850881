import React from 'react'
import { useTranslation } from 'react-i18next'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import isMobileView from '../../util/MobileView'
import SecurityMobile from './SecurityMobile'
import SecurityDesktop from './SecurityDesktop'

export function Security() {
  const { t } = useTranslation()

  return (
    <MarginWrapper>
      {isMobileView() ? (
        <div>
          <SecurityMobile
            t={t}
          />
        </div>

      ) : (
        <SecurityDesktop
          t={t}
        />
      )}
    </MarginWrapper>
  )
}

export default Security
