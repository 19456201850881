import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from './JNJFooter.module.scss'
import logo from '../../assets/J&J New Logo.png'

export default function JNJFooter(props) {
  const [footerSize, setFooterSize] = useState('medium')
  const { t } = useTranslation()

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth

      if (screenWidth <= 413) {
        setFooterSize('small')
      } else if (screenWidth <= 900) {
        setFooterSize('medium')
      } else {
        setFooterSize('large')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div data-testid={`jnj-footer-${footerSize}`} className={styles[`footer-wrapper-${footerSize}`]}>
      <p className={`${styles['footer-text']} ${styles[`footer-text-${footerSize}`]}`}>{t('FooterText')}</p>
      <img className={styles[`footer-image-${footerSize}`]} src={logo} alt={props.imageAlt} />
    </div>
  )
}

JNJFooter.propTypes = {
  imageAlt: PropTypes.string,
}

JNJFooter.defaultProps = {
  imageAlt: 'JnJ Logo',
}
