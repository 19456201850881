export const GATEWAY_API_BASEPATH = process.env.REACT_APP_GATEWAY_API_BASEPATH
export const GATEWAY_API_HEALTH_URL = `${GATEWAY_API_BASEPATH}/api/health`
export const GATEWAY_API_USER_URL = `${GATEWAY_API_BASEPATH}/api/me`
export const GATEWAY_API_ERROR_URL = `${GATEWAY_API_BASEPATH}/error`
export const GATEWAY_API_PROFILE_PICTURE_URL = `${GATEWAY_API_BASEPATH}/api/me/profile-picture`
export const GATEWAY_API_USER_REGISTERED_APPS_URL = `${GATEWAY_API_BASEPATH}/api/me/applications`
export const GATEWAY_API_RESET_PASSWORD = `${GATEWAY_API_BASEPATH}/api/me/reset-password`
export const GATEWAY_API_LOGIN = `${GATEWAY_API_BASEPATH}/api/auth/login`
export const GATEWAY_API_LOGOUT = `${GATEWAY_API_BASEPATH}/api/auth/logout`
export const GATEWAY_API_LOGIN_HISTORY = `${GATEWAY_API_BASEPATH}/api/me/login-history`
export const GATEWAY_API_SUPPORT_MAIL = `${GATEWAY_API_BASEPATH}/api/support/mail`
export const API_AUTH_COOKIE_NAME = 'connect.sid'
export const EXCEPTION_ROUTES = ['/health']
export const GA_ID = process.env.REACT_APP_GA_ID

export const BANNER_MENU_SECTIONS = [
  {
    label: 'home-tab',
    path: '/'
  }
]
export const BANNER_DROPDOWN_MENU_SECTIONS = [
  {
    label: 'account-tab',
    path: '/account',
  },
  {
    label: 'button-reset-password',
    path: '/reset-password',
  },
  {
    label: 'sign-out-tab',
    path: '/logout',
    color: 'red'
  }
]
export const HAMBURGER_MENU_SECTIONS = [
  [
    {
      label: 'home-tab',
      path: '/'
    },
    {
      label: 'my-account-tab',
      path: '/account'
    },
    {
      label: 'button-reset-password',
      path: '/reset-password'
    }

  ],
  [
    {
      label: 'sign-out-tab',
      path: '/logout'
    },
  ]
]
export const SIDE_MENU_SECTIONS = [
  {
    name: 'SideMenuHeaderOne',
    links: [
      { label: 'dashboard-tab', path: '/' }
    ]
  },
  {
    name: 'SideMenuHeaderTwo',
    links: [
      { label: 'my-account-tab', path: '/account' },
    ]
  },
  {
    name: null,
    links: [
      { label: 'log-out-tab', path: '/logout', fontColor: '#EB1700' }
    ]
  },
]

export const FEEDBACK_MODAL_DELAY = process.env.REACT_APP_FEEDBACK_MODAL_DELAY || 5000
export const FEEDBACK_MODAL_MUTE_DURATION = process.env.REACT_APP_FEEDBACK_MUTE_DURATION || '50'

export const SURVEY_TEMPLATE_SCHEMA = `{
  "id": "OA-2024-07-23-1",
  "schema": "1.0.0",
  "questions": [
    {
      "id": "1a",
      "type": "emotion_select",
      "label": {
        "en-US": "",
        "es-ES": "",
        "nl-NL": "",
        "de-DE": "",
        "ko-KR": "",
        "pt-PT": "",
        "ja-JP": "",
        "fr-FR": ""
      },
      "answers": [
        {
          "en-US": "It was great!",
          "es-ES": "¡Fue genial!",
          "nl-NL": "Het was geweldig!",
          "de-DE": "Es war großartig!",
          "ko-KR": "정말 좋았어요!",
          "pt-PT": "Foi ótimo!",
          "ja-JP": "素晴らしかったです！",
          "fr-FR": "C'était génial!"
        },
        {
          "en-US": "It was OK",
          "es-ES": "Estuvo bien",
          "nl-NL": "Het was oké",
          "de-DE": "Es war okay",
          "ko-KR": "괜찮았어요",
          "pt-PT": "Foi ok",
          "ja-JP": "まあまあでした",
          "fr-FR": "C'était correct"
        },
        {
          "en-US": "Needs improvement",
          "es-ES": "Esto necesita mejorar",
          "nl-NL": "Moet verbeterd worden",
          "de-DE": "Muss verbessert werden",
          "ko-KR": "개선이 필요해요",
          "pt-PT": "Precisa melhorar",
          "ja-JP": "改善が必要です",
          "fr-FR": "Doit être amélioré"
        }
      ],
      "required": true
    },
    {
      "id": "1b",
      "type": "free_response",
      "label": {
        "en-US": "Please tell us what needs improvement (optional)",
        "es-ES": "Por favor díganos qué necesita mejorar (opcional)",
        "nl-NL": "Vertel ons wat er verbeterd moet worden (optioneel)",
        "de-DE": "Bitte sagen Sie uns, was verbessert werden muss (optional)",
        "ko-KR": "무엇을 개선해야 하는지 알려주세요 (선택 사항)",
        "pt-PT": "Por favor, diga-nos o que precisa melhorar (opcional)",
        "ja-JP": "改善すべき点を教えてください（任意）",
        "fr-FR": "Veuillez nous dire ce qui doit être amélioré (facultatif)"
      },
      "required": false,
      "dependentOn": {
        "id": "1a",
        "answer": [2]
      }
    }
  ]
}`

export const SURVEY_TEMPLATE = JSON.parse(SURVEY_TEMPLATE_SCHEMA)
export const GATEWAY_API_SURVEY = `${GATEWAY_API_BASEPATH}/api/surveys/${SURVEY_TEMPLATE.id}/response`

export const DEFAULT_IMAGE_CROP = {
  unit: '%',
  width: 80,
  height: 80,
  x: 10,
  y: 10,
  aspect: 1
}
