import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gatewayRequestAxios } from '../../api/interceptor'
import { GATEWAY_API_SUPPORT_MAIL } from '../../util/config'

const initialState = {
  supportObj: {},
  status: 'idle',
  call: 'idle',
  error: null
}

export const sendMail = createAsyncThunk(
  'support/mail',
  async (payload) => {
    const response = await gatewayRequestAxios.post(`${GATEWAY_API_SUPPORT_MAIL}`, payload)
    return response.data
  })

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendMail.pending, (state) => {
        state.status = 'loading'
        state.supportObj = {}
        state.error = undefined
      })
      .addCase(sendMail.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.error = undefined
        state.supportObj = action.payload
      })
      .addCase(sendMail.rejected, (state, action) => {
        state.status = 'failed'
        state.supportObj = {}
        state.error = action.error.message
      })
  }
})

export default supportSlice.reducer

export const selectSupportObject = (state) => state.support.supportObj
export const getSupportCallStatus = (state) => state.support.status
export const getSupportCallError = (state) => state.support.error
