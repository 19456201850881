import React from 'react'
import PropTypes from 'prop-types'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import styles from './Security.module.scss'
import JNJFooter from '../../features/jnj-footer/JNJFooter'

function SecurityMobile({ t }) {
  return (
    <MarginWrapper>
      <div className={styles.mobileLeftAlignStyle}>
        <header style={{ fontSize: '1.5rem', fontWeight: 'bold', paddingBottom: '20px' }}>{t('security-page-header')}</header>
        <hr className={styles.topLine} />
        <header> {t('security-page-message')} </header>
        <div className={styles['jnj-footer']}>
          <JNJFooter text={t('FooterText')} footerSize="medium" />
        </div>
      </div>
    </MarginWrapper>
  )
}

SecurityMobile.propTypes = {
  t: PropTypes.func.isRequired
}

export default SecurityMobile
