import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isAuthenticated } from '../../util/auth'
import { useUserData } from '../user/useUserData'
import { GATEWAY_API_LOGIN } from '../../util/config'

import {
  selectUserObject,
  selectUserCallStatus,
  fetchUser,
} from '../user/userSlice'
import {
  selectProfilePictureImage,
  selectProfilePictureStatus,
  fetchProfilePicture
} from '../user/profilePictureSlice'

const AuthGuard = (WrappedComponent) => {
  function AuthenticatedComponent(props) {
    if (!isAuthenticated()) {
      if (sessionStorage.getItem('wasLoggedIn')) {
        sessionStorage.setItem('logoutWarning', 'true')
      } else {
        sessionStorage.removeItem('logoutWarning')
      }

      localStorage.setItem('redirect', window.location.pathname)
      window.location.href = GATEWAY_API_LOGIN
      return false
    }
    sessionStorage.setItem('wasLoggedIn', 'true')

    const { userObjData, userProfilePictureData, isUserDataLoading } = useUserData(
      selectUserObject,
      selectUserCallStatus,
      selectProfilePictureImage,
      selectProfilePictureStatus,
      fetchUser,
      fetchProfilePicture,
      useSelector,
      useDispatch
    )

    return (
      <WrappedComponent
          /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        userObjData={userObjData}
        userProfilePictureData={userProfilePictureData}
        isUserDataLoading={isUserDataLoading}
      />
    )
  }

  return AuthenticatedComponent
}

export default AuthGuard
