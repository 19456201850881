const mobileKeywords = /Mobi|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i
const WIDTH_THRESHOLD = 834
const PONTS_THRESHOLD = 0

const isTabletDevice = () => !mobileKeywords.test(navigator.userAgent) && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > PONTS_THRESHOLD

const isMobileView = (customWidth) => {
  const isTablet = isTabletDevice()
  const isMobileDevice = mobileKeywords.test(navigator.userAgent) || isTablet
  const screenWidthThreshold = customWidth !== undefined ? customWidth : WIDTH_THRESHOLD
  const isNarrowScreen = window.innerWidth <= screenWidthThreshold

  return isMobileDevice || isNarrowScreen
}

export default isMobileView
