import React from 'react'
import PropTypes from 'prop-types'
import styles from './IconButton.module.scss'

function IconButton({
  icon: Icon, onClick, label, style
}) {
  return (
    <button
      type="button"
      className={styles.iconButton}
      onClick={onClick}
      style={{
        borderColor: style.borderColor,
        color: style.color,
        backgroundColor: 'transparent',
        ...style,
      }}
    >
      <Icon className={styles.icon} />
      <span className={styles.label}>{label}</span>
    </button>
  )
}

IconButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.string),
}

IconButton.defaultProps = {
  style: {},
}

export default IconButton
