import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { gatewayRequestAxios } from '../../api/interceptor'
import { GATEWAY_API_LOGIN_HISTORY } from '../../util/config'

const historyState = {
  total: 0,
  history: []
}

const initialState = {
  loginHistoryObj: { ...historyState },
  status: 'idle',
  error: null
}

export const fetchLoginHistory = createAsyncThunk('me/login-history', async ({ pageSize, pageOffset } = {}) => {
  const response = await gatewayRequestAxios.get(GATEWAY_API_LOGIN_HISTORY, {
    params: {
      pageSize,
      pageOffset,
    }
  })

  const jsonResp = response.data

  return jsonResp
})

export const loginHistorySlice = createSlice({
  name: 'login-history',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLoginHistory.pending, (state) => {
        state.status = 'loading'
        state.loginHistoryObj = { ...historyState }
        state.error = undefined
      })
      .addCase(fetchLoginHistory.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.error = undefined
        state.loginHistoryObj = action.payload
      })
      .addCase(fetchLoginHistory.rejected, (state, action) => {
        state.status = 'failed'
        state.loginHistoryObj = { ...historyState }
        state.error = action.error.message
      })
  }
})

export default loginHistorySlice.reducer

export const selectLoginHistoryObject = (state) => state.loginHistory.loginHistoryObj
export const getLoginHistoryStatus = (state) => state.loginHistory.status
export const getLoginHistoryError = (state) => state.loginHistory.error
